.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* styles */

a {
  text-decoration: none !important;
}

/* label,
input,
a,
div {
  font-size: 14px !important;
} */

.font-25 {
  font-size: 25px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-17 {
  font-size: 17px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(163 174 208 / var(--tw-text-opacity));
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
  border-color: #0d6efd !important;
}

/* input:not([type="checkbox"]), */
button {
  border-radius: 0.375rem;
}


.icon {
  /* height: 40px; */
  width: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #F2F2F2;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* .container {
  min-height: 100vh;
} */

.digy-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.digy-social-media {
  height: 20px !important;
  width: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.digy-image-span {
  border: 1px solid lightgrey;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.digy-auth-card {
  min-height: 100%;
  background-color: #1354FE;
  border-radius: 10px;
}

.inset-0 {
  inset: 0px !important;
}

.z-50 {
  z-index: 50 !important;
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8 !important;
}

.digy-bg-opacity {
  /* background-color: rgb(233 236 239 / var(--tw-bg-opacity)) !important;
  opacity: var(--tw-bg-opacity); */
  background-color: #e9ecef;
}

.page-loader {
  z-index: 1031;
  overflow: hidden;
}

.main-content .page-loader {
  margin-left: 72px;
  margin-top: 42px;
}

.main-content .modal .page-loader {
  margin-left: 0;
  margin-top: 0;
}

.rounded-full svg {
  height: 75px;
  width: 75px;
}

.digy-bg-body-tertiary {
  box-shadow: 0px 2px 5px #98949466 !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  --bs-bg-opacity: 1 !important;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.digy-product-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.digy-user-menu {
  position: absolute;
  box-shadow: 0px 2px 5px #98949466;
  right: 0px;
  top: 60px;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid lightgrey;

  z-index: 1 !important;
  --bs-bg-opacity: 1 !important;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.digy-avatar {
  height: 50px;
  width: 50px;
  border: 1px solid lightgrey;
  border-radius: 50%;
}

.digy-sidebar {
  width: 5% !important;
  display: flex !important;
  justify-content: center !important;
  min-height: 100% !important;
  position: fixed !important;
  /* box-shadow: var(--bs-box-shadow-lg) !important; */
}

.list-unstyled svg {
  height: 20px !important;
  width: 20px !important;
}

.digy-dashboard-card {
  height: auto !important;
  overflow-y: scroll;
  margin-top: 25px !important;
  /* margin-left: calc(100% - 95%) !important; */
  margin-top: 7px !important;
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 254 / var(--tw-bg-opacity));
  padding: 20px 10px;
  scrollbar-width: thin;
}

.digy-box1 {
  /* height: 150px; */
  height: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #98949466;
  padding: 10px;
  color: #fff !important;
  background-color: #FFF;
  /* background: red; */
}

.digy-box1 h5 {
  font-size: 25px;
}

.digy-box1 h1 {
  font-size: 50px;
}

.digy-box1 .box-left {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.digy-box1 .box-left svg {
  font-size: 25px;
  color: #FFF;
}

.digy-p1 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
}

.digy-p2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 !important;
}

.digy-table-card {
  /* height: 380px; */
  height: 450px;
  padding: 10px 10px;
  border: 1px solid lightgrey;
  box-shadow: 0px 2px 5px #98949466;
  border-radius: 5px;
  background-color: #FFF;
}

.digy-module-card {
  height: 67%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.digy-box {
  height: 83px;
  width: 83px;
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
  font-size: 1.25rem !important;
  line-height: 1.75rem;
}

.digy-chart-line,
.digy-chart-bar {
  box-shadow: 0px 2px 5px #98949466;
  background: #FFF;
  border-radius: 5px;
  padding: 10px;
  height: 400px;
}

/* .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e")
} */
/* React Multi Select */
.react-select .select__menu {
  z-index: 6;
}

.multi-select .select__multi-value,
.react-select .select__single-value {
  z-index: 1;
}

.multi-select .select__multi-value__label {
  padding-top: 1px;
  padding-bottom: 1px;
}

.react-select.multi-select .collapse-group-heading>.select__group-heading {
  background-color: #e9ecef;
  margin: 0;
  padding-left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  text-transform: none;
  flex: 1;
}

.react-select.multi-select .collapse-group-heading::before {
  flex-shrink: 0;
  width: 36px;
  height: 34px;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-19bqh2r'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-size: 1.25rem;
  background-position: center center;
  color: rgb(197, 197, 197);
  transform: rotate(180deg);
  background-color: #e9ecef;
}

.react-select.multi-select .collapse-group-heading {
  display: flex;
}

.react-select.multi-select .collapsed-group>div:not(.collapse-group-heading) {
  display: none;
}

.react-select.multi-select .collapsed-group .collapse-group-heading::before {
  transform: rotate(0deg);
}

/* // React Multi Select END // */