/* Auth Page */
body a:active,
body a:focus,
body a:hover {
    text-decoration: none;
}

.auth-bg {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    /* background: url(../images/bg.49756b7….svg) transparent; */
    background-color: #F5F5F7;
    background-size: cover;
    z-index: -1;
}

.flex-root {
    flex: 1;
}

.flex-column-fluid {
    flex: 1 0 auto;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-stack {
    justify-content: space-between;
    align-items: center;
}

.rotate-320 {
    -webkit-transform: rotate(320deg);
    transform: rotate(320deg);
}

.auth-page .fs-6 {
    font-size: 0.9rem !important;
}

.fs-7 {
    font-size: 0.77rem;
}

.text-gray-500 {
    color: #99a1b7;
}

.text-gray-700 {
    color: #4b5675;
}

.link-primary {
    color: #1b84ff !important;
}

.w-125px {
    width: 125px;
}

.w-275px {
    width: 275px;
}

.h-15px {
    height: 15px;
}

.h-20px {
    height: 20px;
}

.h-60px {
    height: 60px;
}

.h-100vh {
    height: 100vh;
}

.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}

.px-5 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.p-10 {
    padding: 2.5rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.mb-9 {
    margin-bottom: 2.25rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}

.separator.separator-content {
    display: flex;
    align-items: center;
    border-bottom: 0;
    text-align: center;
}

.separator {
    display: block;
    height: 0;
    border-bottom: 1px solid #ebebeb;
}

.separator.separator-content::after,
.separator.separator-content::before {
    content: " ";
    width: 50%;
    border-bottom: 1px solid #ebebeb;
}

.separator.separator-content::before {
    margin-right: 1.25rem;
}

.separator.separator-content::after {
    margin-left: 1.25rem;
}

.social-log-btn {
    width: 40px;
    height: 40px;
}

.auth-page input {
    font-size: 14px;
    padding-left: 0;
}

.input-group .last-child {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
}

.input-group .first-child {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
}

.auth-page .input-group input:focus,
.auth-page .input-group input:focus-visible,
.auth-page .input-group-text:focus,
.auth-page .input-group-text:focus-visible {
    border-color: #dee2e6 !important;
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.auth-page .input-group:focus .input-group-text,
.auth-page .input-group:focus-visible .input-group-text,
.auth-page .input-group:focus input,
.auth-page .input-group:focus-visible input {
    border-color: #317bff;
    box-shadow: none;
    outline: none;
}

.error-message {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #f04454;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #f04454;
}

.has-validation .invalid-feedback {
    display: block;
}

.right-bg {
    background-color: #1354FE;
}

@media (min-width: 768px) {
    .px-md-15 {
        padding-right: 3.75rem;
        padding-left: 3.75rem;
    }

    .w-md-50 {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .flex-lg-row-fluid {
        flex: 1 auto;
        min-width: 0;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-500px {
        width: 500px;
    }

    .w-lg-500px {
        width: 500px;
    }

    .w-lg-550px {
        width: 550px;
    }

    .h-lg-75px {
        height: 75px;
    }

    .h-lg-100vh {
        height: 100vh;
    }

    .py-lg-15 {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
    }

    .mb-lg-12 {
        margin-bottom: 3rem;
    }

    .mb-lg-20 {
        margin-bottom: 5rem;
    }
}

@media (min-width: 1200px) {
    .w-xl-500px {
        width: 500px;
    }
}

/* // Auth Page // */

/* Auth V1 CSS */
.auth-page.auth-v1 {
    width: 940px;
    min-height: 520px;
    background-color: #fff;
    box-shadow: 0px 2px 30px #ccc6;
    margin: auto;
    position: relative;
    z-index: 1;
    /* margin-top: 7%; */
    overflow: hidden;
    flex: 0 0 auto;
}

.auth-l-box {
    width: 550px;
    min-height: 540px;
    height: auto;
    background: #fff;
    padding: 50px;
    border-radius: 2px;
    transition: all .1s ease-in-out;
    overflow-y: auto;
    border-right: 2px solid #f1f1f1;
    position: relative;
}

.auth-r-box {
    width: 390px;
    height: auto;
    padding: 40px;
    background-color: #fff;
    overflow: hidden;
}

.auth-r-box .main-img {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
    background-size: 100%;
}

.auth-r-box .heading {
    font-size: 16px;
    font-weight: 600 !important;
    margin-top: 20px;
}

.auth-r-box .content {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}

.auth-r-box .btn {
    width: max-content;
    max-width: 160px;
    margin: auto;
    margin-top: 20px;
    background-color: #ECF7FE;
    color: #0091FF;
    font-weight: 600;
    border-radius: 18px;
    padding: 8px 20px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 14px;
}

.auth-r-box .heading,
.auth-r-box .content,
.auth-r-box .btn {
    display: block;
    line-height: 20px;
    text-align: center;
}

/* Forgot Password */
.auth-page.forgot-password {
    max-width: 540px;
    min-height: auto;
}

.forgot-password .auth-l-box {
    min-height: auto;
}

.fgot-pass-opts {
    color: #6f6f6f;
    padding: 16px 0;
    cursor: pointer;
}

.fgot-pass-opts:hover {
    background-color: #ededed;
    color: #222222;
}

/* Forgot Password */

@media (max-width: 991.98px) {
    .auth-page.auth-v1 {
        width: auto;
    }
}

@media (max-width: 575.98px) {
    .auth-page.auth-v1 {
        width: 100%;
        box-shadow: none;
        margin: 0px auto;
        position: relative;
        z-index: 1;
        height: 100%;
        overflow: hidden;
        flex: 1 0 auto;
    }

    .auth-l-box {
        width: 100%;
        padding: 30px;
        height: auto;
        border-right: none;
    }
}

/* Auth V1 CSS */