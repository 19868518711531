/* Common Styles */
:root {
    --dark-blue: #29409d;
    --light-blue: #f5f9ff;
    --light-blue-border: #d2e3ef;
    /* Tree View */
    --c-body-font-clr: #000;
    --c-primary-focus-color: #03a9f5;
    --c-primary-anchor-clr: #03a9f5;
    --c-primary-btn-bg: #f0483e;
    --c-white: #fff;
    --c-zsh-place-fontcolor: #878787;
    --c-zf-hover: #f6f8fb;
    --c-highlight: #448FF7;
    --c-primary-color: #272F43;
    --c-top-bar-color: #262F45;
    --c-cta: #1AB394;
    --c-cta-padding: 12px 20px;
    --c-dark-text: #333333;
    --c-text-color: #272F43;
    --c-b-radius: 3px;
    --c-fontsize14: 14px;
    --c-fontsize16: 16px;
    --c-fontsize12: 12px;
    --c-gray: #555555;
    --c-body-bg: #F5F6F8;
    --c-discard: #A8A8A8;
    --c-gray-100: #ECECEC;
    --c-gray-60: #9d9d9d;
    --c-gray-50: #f5f6f8;
    --c-gray-10: #bbbbbb;
    --c-gray-200: #d6d4d4;
    --c-light-gray: #F4F4F4;
    --c-dark-blue-gray: #464D5D;
    --c-charcoal-blue: #464D5D;
    --c-sonic-silver: #7C7C7C;
    --c-light-black: rgba(0, 0, 0, 0.7);
    --c-silver-gray: #D3D3D3;
    --c-corl: #ff6347;
    --c-off-gray: #FAFAFA;
    --c-mist-slate: #DDDDDD80;
    --c-dark-charcoal: #333;
    --c-soft-silver: #E3E3E3;
    --c-granite-gray: #848484;
    --c-red: red;
    --c-teal: #20A58A;
    --c-celadon-green: #52AD93;
    --c-dodger-blue: #2680EB;
    --c-alice-blue: #edf4fd;
    --c-border-gray: #ddd;
    --c-pale-gray: #eee;
    --c-vibrant-red: #EF4C4C;
    --c-aliceBlue: #ECF4FE;
    --c-mintcream: #EEF6F5;
    --c-drak-sea-green: #88bcaa;
    --c-keppel: #36bc9e;

    --bd-light-info: #edf6ff;
    --bd-light-info-rgb: 128, 0, 255;
}

/* html, body, #root, .App {
    height: 100%;
} */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
}

body {
    font-size: 14px;
    background-color: #F5F5F7 !important;
}

.bg-light-blue {
    background-color: var(--light-blue);
}

.bg-dark-blue {
    background-color: var(--dark-blue);
}

.bg-active-success.active {
    background-color: var(--bs-teal) !important;
}

.border-light-blue {
    border-color: var(--light-blue-border);
}

.cursor-grab {
    cursor: grab !important;
}

.cursor-pointer,
::file-selector-button {
    cursor: pointer !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.w-40 {
    width: 40%;
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.min-w-auto {
    min-width: auto !important;
}

.min-w-80px {
    min-width: 80px;
}

.min-w-120px {
    min-width: 120px;
}

.min-w-140px {
    min-width: 140px;
}

.max-w-90p {
    max-width: 90%;
}

.h-5px {
    height: 5px;
}

.h-10px {
    height: 10px;
}

.h-15px {
    height: 15px;
}

.h-20px {
    height: 20px;
}

.h-25px {
    height: 25px;
}

.h-30px {
    height: 30px;
}

.h-35px {
    height: 35px;
}

.h-40px {
    height: 40px;
}

.h-45px {
    height: 45px;
}

.h-50px {
    height: 50px;
}

.h-55px {
    height: 55px;
}

.h-60px {
    height: 60px;
}

.h-65px {
    height: 65px;
}

.h-70px {
    height: 70px;
}

.h-75px {
    height: 75px;
}

.h-80px {
    height: 80px;
}

.h-85px {
    height: 85px;
}

.h-90px {
    height: 90px;
}

.h-95px {
    height: 95px;
}

.h-100px {
    height: 100px;
}

.h-105px {
    height: 105px;
}

.h-110px {
    height: 110px;
}

.h-115px {
    height: 115px;
}

.h-120px {
    height: 120px;
}

.max-h-200px {
    max-height: 200px;
}

.max-h-400px {
    max-height: 400px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.me-2px {
    margin-right: 2px !important;
}

.table-responsive,
.overflow-auto,
.overflow-y-auto,
.ag-body-vertical-scroll-viewport {
    scrollbar-width: thin;
}

.form-floating>.input-group~label::after {
    position: absolute;
    inset: 1rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: #fff;
    border-radius: 0.375rem;
}

/* Form Floating labels common design */
.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-select~label,
.form-floating>.input-group~label {
    width: auto;
    opacity: 1;
    transform: scale(.85) translateY(-1.4rem) translateX(.15rem);
}

.form-floating>label {
    color: #212529a6;
}

.form-floating>.form-check~label {
    padding: 8px 0 8px 24px;
    height: auto;
}

/* Multi select box floating label design */
.form-normal .multi-select label {
    transform: scale(.85) translateY(-0.8rem) translateX(.15rem);
    z-index: 1;
    position: absolute;
    color: #212529a6;
}

.form-normal .multi-select label::after {
    position: absolute;
    inset: 0rem -0.32rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
}

.form-floating .multi-select input::placeholder {
    visibility: hidden;
    opacity: 0;
}

.form-floating .multi-select:focus input::placeholder {
    visibility: visible;
    opacity: 1;
}

.form-floating .multi-select:focus~label {
    height: fit-content;
}

.form-floating .multi-select:focus~label::after {
    position: absolute;
    inset: 0.5rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: #ffffff;
    border-radius: 0.375rem;
}

.form-floating .input-group>.form-control:focus,
.form-floating .input-group>.form-select:focus {
    z-index: 2;
}

/* Form Floating label - Height Reduce CSS */
.form-floating {
    min-height: calc(2.2rem + 2px);
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select,
.form-floating>.form-check {
    height: calc(2.2rem + 4px);
    min-height: calc(2.2rem + 4px);
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select,
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding: 0.625rem 0.75rem;
}

.form-floating>label {
    padding: 0.5rem 0.75rem;
}

/* // Form Floating label - Height Reduce CSS // */

/* With Place holder - Use with ".w-placeholder" class */
.form-floating.w-placeholder>.form-control:focus::placeholder {
    color: #212529bf;
}

/* // Form Floating labels common design // */

.form-control {
    border-radius: .375rem;
}

/* Button styles */
.btn {
    font-family: "Roboto", sans-serif;
    font-size: 14px !important;
    padding: 4px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn.light-blue.btn-plain-border {
    border: 1px solid transparent;
    color: #000000;
}

.btn.light-blue,
.btn.light-blue.btn-plain-border:hover {
    background: #F5F9FF;
    border: 1px solid #317BFF;
    color: #317BFF;
}

.btn.light-blue.btn-plain-border {
    background: transparent;
}

.btn.white-blue {
    background: #FFFFFF;
    border: 1px solid #317BFF;
    color: #317BFF;
}

.btn.white-blue:hover {
    background: #F5F9FF;
    border: 1px solid #317BFF;
    color: #317BFF;
}

.btn.flat-btn {
    background-color: #FFFFFF;
    border-color: #EBEBEB;
}

.btn.flat-btn:hover {
    background-color: #EBEBEB;
}

.btn.flat-gray-btn {
    background-color: #F5F9FF;
    border-color: #D0D4DF;
}

.btn.flat-gray-btn:hover {
    background-color: #EAEEF5;
    border-color: #D0D4DF;
}

.btn-group .btn {
    margin-right: 0.6rem;
}

.btn.btn-digy-dark {
    background-color: #29409d;
    border-color: #29409d;
    color: #ffffff;
}

.btn.btn-digy-dark-outline {
    border-color: #29409d;
    color: #29409d;
}

.btn-light-info {
    --bs-btn-color: #338cf0;
    --bs-btn-bg: var(--bd-light-info);
    --bs-btn-border-color: #338cf0;
    --bs-btn-hover-color: #338cf0;
    --bs-btn-hover-bg: #e2f0ff;
    --bs-btn-hover-border-color: #338cf0;
    --bs-btn-focus-shadow-rgb: 50, 180, 255;
    --bs-btn-active-color: #338cf0;
    --bs-btn-active-bg: #e2f0ff;
    --bs-btn-active-border-color: #338cf0;
}

/* // Button styles // */

.form-switch {
    font-size: 20px;
    min-height: 20px;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e"); */
}

.form-switch.form-check-success .form-check-input:checked,
.form-switch.form-check-success .form-check-input:checked:active,
.form-switch.form-check-success .form-check-input:checked:focus {
    background-color: #72c98a;
    border-color: #72c98a !important;
}

/* Dropdown with arrow */
.with-arrow.dropdown-toggle {
    position: relative;
}

.with-arrow.dropdown-toggle::before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url('../images/icons/triangle-left.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 10px;
    top: 0;
    transform: translate(0, 50%) rotate(90deg);
}

.with-arrow.dropdown-toggle.show::before {
    transform: translate(0, 50%) rotate(270deg);
}

body>div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1055;
}

body>.modal-backdrop.show:nth-last-child(2),
body>.offcanvas-backdrop.show:nth-last-child(2) {
    z-index: 1050;
}

body>div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1045;
}

body>.modal-backdrop.show:nth-last-child(4),
body>.offcanvas-backdrop.show:nth-last-child(4) {
    z-index: 1040;
}

body>div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1035;
}

body>.modal-backdrop.show:nth-last-child(6),
body>.offcanvas-backdrop.show:nth-last-child(6) {
    z-index: 1030;
}

/* // Common Styles // */

/* Top Header */
.top-header.navbar {
    padding-top: 14px;
    padding-bottom: 14px;
    z-index: 1031;
}

.navbar-brand {
    cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    white-space: nowrap;
}

.navbar-nav li {
    align-self: center;
}

.top-header .navbar-nav li .nav-link {
    /* width: 40px;
    height: 40px; */
    width: 32px;
    height: 32px;
    line-height: 16px;
    border: 1px solid #ececec;
    border-radius: 40px;
    background-color: #F9F9F9;
}

.com-logo img,
.user-icon {
    object-fit: scale-down;
    overflow: hidden;
    text-wrap: auto;
}

.user-icon {
    border-radius: 50rem;
    font-size: xx-small;
}

.search-box .searchfield {
    border: none;
    height: 30px;
    line-height: 22px;
    /* -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 4px 6px 5px 6px; */
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    max-width: 380px;
    width: 100%;
    font-size: 14px;
    /* -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topright: 30px;
    border-top-right-radius: 30px;
    -webkit-border-bottom-right-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    border-bottom-right-radius: 30px;
    -webkit-border-top-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-left-radius: 0px; */
    /* -webkit-box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06);
    box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06); */
    /* margin-left: -5px; */
    outline: none;
    color: #4b4c4e;
    background-color: #F9F9F9;
}

.search-box .searchbutton {
    display: inline-block;
    border: none;
    line-height: 14px;
    /* padding: 4px 5px 4px 15px; */
    width: 16px;
    height: 16px;
    vertical-align: middle;
    /* -webkit-border-top-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-left-radius: 30px; */
    /* -webkit-box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06);
    box-shadow: inset 3px 5px 0px -4px rgba(0, 0, 0, 0.06); */
    background-color: transparent;
}

.top-header .navbar-nav.module-links-list>li .dropdown-menu {
    width: fit-content;
}

.top-header .navbar-nav.module-links-list>li .dropdown-menu ul {
    height: auto;
    max-height: 550px;
    overflow-x: hidden;
}

.top-header .module-links-list>li>.btn {
    font-family: "Inter", sans-serif;
}

/* // Top Header // */

/* Top Bottom Header  */
.navbar.tb-header {
    padding-top: 8px;
    padding-bottom: 8px;
    top: 41px;
}

.navbar .dropdown-menu {
    font-size: 14px;
}

.navbar .dropdown .dropdown-item {
    cursor: pointer;
}

.navbar .dropdown-item.link-primary:active {
    background-color: transparent;
}

.navbar .dropdown>.btn.show,
.navbar .dropdown>.btn:active {
    outline: none;
    border: none;
}

/* // Top Bottom Header // */

/* Sidebar */
.sidebar {
    /* top: 132px; */
    /* top: 70px; */
    top: 41px;
    border-right: 1px solid #EBEBEB;
}

.draggable-btns .btn {
    color: #212529;
    font-size: 13px;
    border-radius: 4px;
    padding: 4px 8px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    cursor: grab;
    border-color: #EBEBEB;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 48.5%, #FFFFFF 100%);
}

.draggable-btns .btn:hover {
    color: #212529;
    background-color: #fff2d6;
    background-image: none;
}

/* Main Sidebar */
.main-sidebar .list-btns .list-item.active {
    border-left-width: 4px;
    border-left-style: solid;
}

.main-sidebar {
    width: 72px !important;
}

.with-sidebar .create-module .content-section {
    /* width: calc(100% - 360px); */
    /* margin-left: 72px; */
}

.with-sidebar .create-module .sidebar {
    left: 72px;
}

/* // Sidebar // */

/* Card Drop Box Section */
.dashboard .content-section {
    background-color: transparent !important;
}

.dashboard .react-grid-item {
    box-shadow: none;
    border: none;
}

.card.drop-box {
    padding: 0;
}

.drop-box .card-header,
.styled-card .card-header {
    /* background-color: #E6F4FF; */
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.drop-box .card-body {
    cursor: auto;
}

.drop-box .droppable-area {
    position: relative;
}

.drop-box .field-container {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #fff;
    cursor: auto;
}

.drop-box .field-container:hover {
    background: #F5F9FF;
    border: 1px solid #317BFF;
}

.section-name-input {
    border-bottom-width: 0px;
}

.custom-input-border {
    border-width: 0;
    border-radius: 6px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-input-border:focus,
.custom-input-border:focus-visible {
    outline: none;
}

.custom-input-border:hover,
.custom-input-border:focus,
.custom-input-border:focus-visible {
    text-overflow: clip;
}

/* // Card Drop Box Section // */

/* Dashboard Cards Page */
.main-panel {
    background-color: #F5F5F7;
    position: relative;
    /* top: 59px; */
    top: auto;
    /* height: calc(100vh - 41px); */
    height: 100%;
}

.sub-title,
.count-num {
    line-height: 44px;
}

.count-num {
    font-size: 28px;
}

/* // Dashboard Cards Page // */

/* Dashboard Setup Page */
.search-box {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.setup-actions .search-box .searchfield,
.search-box .searchfield {
    height: 30px;
    background: transparent;
    border: 0;
    border-radius: 50rem !important;
    padding: 8px 12px 8px 30px;
    width: 100%;
    display: block;
    box-shadow: none;
    box-sizing: border-box;
}

.setup-actions .search-box .searchbutton,
.search-box .searchbutton {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 8px;
    right: auto;
    top: calc(50% - 7.5px);
    z-index: 10;
    cursor: default;
    padding: 0;
}

.setup-card .list-group-flush .list-group-item {
    padding-left: 0;
    border-bottom: 0;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.setup-card .list-group-flush .list-group-item .list-group-item {
    cursor: pointer;
}

.setup-card .list-group-item.active,
.setup-card .list-group-item.active:hover {
    background-color: #29409d;
    border-color: #29409d;
    color: #ffffff;
}

.setup-card .list-group-item:hover {
    color: var(--bs-primary);
}

.setup-card .list-group .btn,
.setup-card .list-group a {
    font-size: 14px;
    transition: all 0.55 linear;
}

.setup-card .list-group-item>.btn[aria-expanded="true"]>img {
    transform: rotate(90deg);
}

.setup-card .list-group .btn>img {
    width: 8px;
    height: 8px;
}

.setupbox-height {
    height: calc(100vh - 170px);
}

.setup-module-height {
    height: calc(100vh - 106px);
}

/* // Dashboard Setup Page // */

/* Lead Create Form */
.lead-create-form,
.lead-create-form input,
.lead-create-form select,
.lead-create-form textarea,
.form-control,
.form-select {
    font-size: 14px !important;
}

.sec-header {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 25px;
}

/* Photo Upload */
.photo-upload .hover-img {
    position: absolute;
    cursor: pointer;
    visibility: hidden;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 22px;
    height: 19px;
    margin: auto;
    z-index: 2;
}

.hover-img .photo-icon {
    display: inline-block;
    margin: 0 auto;
    width: 22px;
    height: 20px;
    background-image: url('../images/icons/camera.svg');
    border: 0;
    cursor: pointer;
}

.profile-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
}

.profile-icon img {
    height: 100%;
    width: 100%;
    max-height: initial;
    /* opacity: .3; */
    object-fit: scale-down;
    border-radius: 50rem;
}

.photo-upload:hover::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1;
}

.photo-upload:hover .hover-img {
    opacity: 1;
    visibility: visible;
}

/* Photo Upload Modal */
.file-upload-content {
    min-height: 328px;
    max-height: 400px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 38px 10px 30px;
    border-radius: 4px;
    border: 2px dashed #EBEBEB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}

/* Photo Upload Modal */

.file-upload-box {
    align-items: center;
    background-color: #fefefe;
    border: 1px dashed #bebebe;
    border-radius: 8px;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    justify-content: center;
    min-height: 150px;
    overflow: hidden;
    padding: 15px;
    position: relative;
}

.file-upload-box .file-upload-input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.file-upload-box:hover,
.file-upload-box:focus {
    border-color: var(--bs-primary);
    outline: none;
}

.file-upload-box.file-upload-small {
    min-height: 70px;
}

/* Photo Upload */

.lead-create-form .input-control,
.com-details-form .input-control {
    min-width: 230px;
    max-width: 390px;
    align-self: flex-start;
    flex: 1;
}

.form-group {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.form-group>label {
    width: 210px;
    box-sizing: border-box;
    position: relative;
    overflow-wrap: anywhere;
    word-break: break-word;
    line-height: 17px;
    padding-right: 36px;
    padding-top: 9px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;
    color: #616E88;
    font-weight: 500;
}

.lead-create-form .right-side .form-group {
    justify-content: flex-end;
}

input[type='text'][required],
input[type='email'][required],
select[required],
.lead-create-form [required],
.drop-box .field-container.unique,
.form-fields-page .unique {
    border-left: 3px solid #FF5D5A;
}

.input-control .input-group .btn,
.input-control .input-group .btn:focus-visible {
    border-color: #DEE2E6;
    background-color: #ffffff;
    color: var(--bs-btn-color);
    box-shadow: none;
}

.input-control .input-group .dropdown-toggle::after {
    margin-left: 1.255em;
}

.form-footer {
    min-height: 70px;
}

/* // Lead Create Form // */

/* Home Setup Table Page */
.with-charts {
    height: calc(100vh - 45vh);
}

.without-charts {
    height: calc(100vh - 17vh);
}

.rounded-table {
    border-radius: 0.75rem;
    overflow: hidden;
    border: 1px solid #EBEBEB;
}

.rounded-table .table>tbody>tr:last-child>td {
    border-bottom-width: 0;
}

.table>:not(caption)>*>* {
    vertical-align: middle;
    padding: 0.5rem 0.5rem;
}

.symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
}

.symbol.symbol-35px>img {
    width: 35px;
    height: 35px;
}

/* // Home Setup Table Page // */

/* List Contracts Page */
.list-btns .list-item:hover,
.list-btns .list-item.active {
    background: #F5F9FF;
    border-color: #317BFF !important;
}

.list-detail dd {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.list-detail dd::after {
    content: ':';
    position: absolute;
    padding-left: 0.4rem;
}

/* // List Contracts Page // */

/* UI CHANGES */

.create-module {
    /* top: 59px; */
    /* height: calc(100vh - 130px); */
    height: 100%;
}

/* .create-module .sidebar {
    top: 124px;
} */

.lead-create-form .tb-header {
    top: 41px;
    left: 72px;
    /* With main sidebar*/
}

/* General Settings */
/* .users-settings>.card-body {
    height: calc(100vh - 14.2vh);
    overflow-y: auto;
    scrollbar-width: thin;
} */

.personal-settings .card-body {
    height: calc(100vh - 14.2vh);
    overflow-y: auto;
    scrollbar-width: thin;
}

.g-settings .card-header>.nav .nav-item button,
.settings .card-header>.nav .nav-item button {
    color: #202120 !important;
    font-weight: 400;
}

.g-settings .card-header>.nav .nav-item button.active,
.settings .card-header>.nav .nav-item button.active {
    background-color: transparent;
    color: #000000 !important;
    font-weight: 600;
}

.user-list {
    max-height: calc(100vh - 32vh);
    overflow-y: auto;
    scrollbar-width: thin;
}

.user-list .user-list-item {
    cursor: pointer;
}

.user-list .user-list-item.active,
.user-list .user-list-item.active:hover {
    background-color: #E7F2FF;
}

.user-list .user-list-item:hover {
    background-color: #F3F8Fe;
}

.g-settings .card-header>.nav .nav-item button.active::after,
.settings .card-header>.nav .nav-item button.active::after {
    content: "";
    border-bottom: 3px solid var(--bs-primary);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -1px;
    border-radius: 4px 4px 0 0;
}

.g-settings .profile-icon {
    width: 64px;
    height: 64px;
}

.g-settings .user-details .profile-icon {
    width: 94px;
    height: 94px;
}

.g-settings .profile-icon::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #000000;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    bottom: -1px;
    right: 5px;
    background-color: #12aa67;
}

.personal-settings .profile-icon::before {
    bottom: 4px;
    right: 10px;
}

.g-settings .user-details>.collapse-btn[aria-expanded="true"]>.fa-chevron-down {
    transform: rotate(180deg);
}

.g-settings .profile-icon.user-offline::before {
    background-color: #b6b6b6;
}

.s-user-detail,
.activate-users {
    height: calc(100vh - 16.2vh);
    overflow-y: auto;
    scrollbar-width: thin;
}

/* Custom Switch Design */
.switch input {
    display: none;
}

.switch {
    display: inline-block;
    width: 60px;
    height: 30px;
    /* margin: 8px;
    transform: translateY(50%); */
    position: relative;
}

.custom-switch .switch-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #777, 0 0 4px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: .4s;
}

.custom-switch .switch-slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    transition: .4s;
}

.custom-switch input:checked+.switch-slider:before {
    transform: translateX(30px);
    background: limeGreen;
}

.custom-switch input:checked+.switch-slider {
    box-shadow: 0 0 0 2px limeGreen, 0 0 2px limeGreen;
}

/* // Custom Switch Design // */

/* Company Settings */
.company-setting>.card>.card-body {
    height: calc(100vh - 14vh);
}

.company-setting .company-icon::before {
    width: 0;
    height: 0;
    border: none;
}

.com-details-form .form-group>label {
    width: 150px;
    padding-right: 1rem;
}

.fiscal-form,
.new-shift-form {
    margin-bottom: 70px;
}

.dropdown-menu.max-h-200px,
.dropdown-menu.max-h-400px {
    padding: 5px 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.dropdown-menu.max-h-200px .dropdown-item {
    word-wrap: break-word;
    white-space: break-spaces;
}

.custom-dropdown .dropdown-item {
    position: relative;
}

.custom-dropdown .dropdown-item.active::before {
    content: "";
    width: 8px;
    height: 14px;
    position: absolute;
    left: 12px;
    display: inline-block;
    transform: rotate(45deg);
    border-bottom: 3px solid white;
    border-right: 3px solid white;
}

.form-footer,
.shadow-top {
    box-shadow: 0 -0.16rem 0.16rem rgba(0, 0, 0, 0.175);
}

.modal-fullscreen.pre-cal-modal {
    width: 94vw;
}

.pre-calendar table th,
.pre-calendar table>tbody>tr:hover>th {
    background-color: var(--light-blue);
    font-weight: 500;
}

.pre-calendar table>tbody>tr:hover>td {
    background-color: var(--bs-table-hover-bg);
}

.pre-calendar table>tbody>tr:last-child>th {
    border-bottom-width: 0;
}

.pre-calendar table .surplus-week>th,
.pre-calendar table .surplus-week>td,
.pre-calendar table>tbody>tr.surplus-week:hover>th,
.pre-calendar table>tbody>tr.surplus-week:hover>td {
    background-color: #F7EDDA;
}

.pre-calendar table>tbody>tr>th:empty,
.pre-calendar table>tbody>tr>td:empty {
    height: 38px;
}

.pre-calendar table>tbody>tr:hover>td:empty {
    background-color: transparent;
}

.bs-tooltip-bottom {
    padding-top: 0;
}

#weekStartTooltip,
.custom-tooltip-b {
    max-width: 550px;
}

#weekStartTooltip .tooltip-inner,
.custom-tooltip-b .tooltip-inner {
    max-width: inherit;
    text-align: left;
}

.hover-icon-table>tbody>tr>td .hover-icon {
    visibility: hidden;
    opacity: 0;
}

.hover-icon-table>tbody>tr:hover>td .hover-icon {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

.hover-icon-table>tbody>tr>td .delete-icon:hover {
    color: #dc3545;
}

.hover-icon-table>tbody>tr>td .edit-icon:hover {
    color: #338cf0;
}

.shift-user-select .with-arrow.dropdown-toggle::before {
    right: 4px;
}

/* View currency modal - Vertical Tree view */
.tree-cv {
    position: relative;
}

.tree-cv::before,
.tree-cv .content:last-child::before {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 100px;
    width: 1px;
}

.tree-cv::before {
    border-left: 1px solid #D2D9E0;
}

.tree-cv .date-box {
    position: relative;
    display: inline-block;
}

.tree-cv .content {
    position: relative;
    display: flex;
}

.tree-cv .content:last-child:before {
    border-left: 1px solid #ffffff;
}

.tree-cv .time-box {
    flex: none;
    width: 80px;
    text-align: right;
    color: #616E88;
}

.tree-cv .confirm-text {
    position: relative;
    padding-left: 40px;
}

.tree-cv .confirm-text::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 14px;
    background-color: #F4F7FF;
    border: 1px solid #D2D9E0;
}

/* // View currency modal - Vertical Tree view // */
/* // Company Settings // */
/* // General Settings // */

/* Security control - Profiles */
.security-ctrl.profile-set {
    height: calc(100vh - 12vh);
}

.table-responsive .dropdown,
.table-responsive .dropend {
    position: static;
}

/* Roles and sharing  */
.role-sharing>.card>.card-body {
    max-height: calc(100vh - 14vh);
}

/* Tree Design */
ul.tree li {
    list-style: none;
}

.tree {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-left: 0;
    padding-left: 0;
    position: relative;
}

.tree::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 1px;
    height: 18px;
    background-color: var(--c-gray-200);
}

.tree .parent {
    position: relative;
    padding-block: 4px;
}

.tree .parent::before,
.tree .parent::after {
    content: '';
    position: absolute;
}

.tree .parent::before {
    width: 0;
    top: 13px;
    left: -18px;
    border-bottom: 1px dashed var(--c-gray-200);
}

.tree .parent::after {
    display: block;
    width: 0;
    top: 0;
    left: -27.5px;
    height: 39px;
    border-left: 1px solid var(--c-gray-200);
    bottom: 0;
}

.tree .parent:last-child::after {
    height: 5px;
    bottom: auto;
}

.tree .parent::after {
    height: 100%;
    top: 23px;
}

.tree details[open]>.parent::before,
.tree details[open]>.nested-item::after {
    border-left-color: var(--c-drak-sea-green);
}

.tree .parent.parent:last-child::after {
    content: none;
    bottom: auto;
}

.tree details {
    margin: 0 auto;
}

.tree .role-name-box {
    position: absolute;
    line-height: 16px;
}

.tree .role-name-box .link:hover {
    color: var(--bs-primary);
}

.tree .action-btns {
    visibility: hidden;
    opacity: 0;
}

.tree .action-btns .act-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: auto 4px;
    padding: 4px;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border: 1px solid var(--c-gray-60);
}

.tree .role-name-box:hover .action-btns {
    visibility: visible;
    opacity: 1;
}

.action-btns .act-btn.add-btn:hover,
.action-btns .act-btn.edit-btn:hover {
    border-color: #338cf0;
    background-color: #edf6ff;
    color: #338cf0;
}

.action-btns .act-btn.delete-btn:hover {
    border-color: #ff5d5a;
    background-color: #ffe8e9;
    color: #ff5d5a;
}

.tree summary {
    display: inline-block;
    position: relative;
    bottom: 0;
    cursor: pointer;
    padding-left: 26px;
    line-height: 18px;
    height: 18px;
}

.tree summary::before {
    content: '+';
    position: absolute;
    top: 0;
    left: 0px;
    width: 18px;
    height: 18px;
    line-height: 1;
    text-align: center;
    color: var(--c-dark-text);
    border: 1px solid var(--c-gray-60);
    border-radius: 0%;
    background-color: var(--c-white);
    z-index: 10;
}

.tree details[open]>summary::before {
    content: '-';
    border: 2px solid var(--c-gray-60);
    color: var(--c-dark-text);
    /* line-height: 0.9; */
}

.tree details[open]>.tree.parent::after {
    display: block;
    width: 0;
    top: 0;
    left: -27.5px;
    height: 39px;
    border-left: 1px solid var(--c-gray-60);
    /* If need Change border color here */
    bottom: 0;
}

.tree .details>.nested-list {
    padding: 15px;
}

.tree .details .nested-list {
    padding: 0 0 0 30px;
}

.tree .nested-item {
    position: relative;
    padding: 20px 0 0 0;
    margin-top: 0;
    line-height: 1;
    min-height: 40px;
}

.tree .nested-item::before,
.tree .nested-item::after {
    content: '';
    position: absolute;
    left: -17px;
}

.tree .nested-item::before {
    width: 22px;
    top: 28px;
    left: -22px;
    border-bottom: 2px dashed var(--c-gray-60);
}

.tree .nested-item::after {
    display: block;
    width: 0;
    top: -20px;
    left: -22px;
    height: 45px;
    height: 100%;
    border-left: 2px dashed var(--c-gray-60);
    bottom: 0;
}

.tree .nested-item:last-child::after {
    top: -18px;
    height: 48px;
    bottom: auto;
}

.tree .label {
    display: inline-block;
    /* font-size: 10px; */
    padding-inline: 3px;
    color: #929292;
    margin-left: 10px;
    border: 1px solid var(--c-gray-100);
}

.tree>.parent:last-child {
    padding-bottom: 0;
}

/* .tree .nested-item:last-of-type .nested-item {
    padding-top: 6px;
} */

.tree-title {
    font-size: 12px;
}

.tree-title:hover {
    color: var(--c-keppel);
    cursor: pointer;
}

/* // Tree Design END //  */

/* Profile Permission page */
.pro-permission {
    position: relative;
}

.simple-list-scrollspy .scrollspy-item {
    cursor: pointer;
    width: 100%;
    padding: 7px 30px 7px 45px;
    border-left: 5px solid transparent;
    color: #333333;
}

.simple-list-scrollspy .scrollspy-item:hover {
    color: var(--bs-primary);
    background-color: #e7f2ff;
}

.simple-list-scrollspy .scrollspy-item.active,
.simple-list-scrollspy .scrollspy-item.active:hover {
    border-left: 5px solid #338cf0;
    color: var(--bs-primary);
    background-color: #e7f2ff;
}

.simple-list-scrollspy .sub-list-header {
    padding-left: 45px;
}

.scrollspy-list,
.scrollspy-content,
.pdf-temp-design .temp-view {
    min-height: calc(100vh - 102px);
    max-height: calc(100vh - 102px);
}

.scrollspy-content .per-setup {
    max-width: 780px;
}

.per-setup>.row {
    border-bottom: 1px solid var(--c-border-gray);
    padding: 2px 0;
}

.per-setup>.row:hover {
    background-color: var(--c-off-gray);
}

.per-setup .btn.btn-link {
    color: var(--bs-body-color);
    text-decoration: none;
}

.per-setup .with-arrow.dropdown-toggle::before {
    right: 4px;
    top: -1px;
}

.per-setup .dropdown-item.active,
.per-setup .dropdown-item:active {
    background-color: var(--c-off-gray);
    color: var(--bs-body-color);
}

/* // Profile Permission page // */

/* Audit Log Page */
.audit-log-page,
.log-history-page {
    height: calc(100vh - 8.4vh);
}

.audit-log-page .filter .dropdown>.with-arrow.dropdown-toggle::before {
    top: -1px;
}

.info-tooltip .tooltip-inner {
    background-color: #f1f1f1;
    color: var(--bs-body-color);
    max-width: 520px;
    text-align: start;
}

.info-tooltip.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: #f1f1f1;

}

.audit.custom-popover {
    max-width: 376px;
}

/* // Audit Log Page // */

/* Email configuration */
.email-config>.card>.card-body {
    max-height: calc(100vh - 13.6vh);
}

/* // Email configuration // */

/* Business Messaging Page */
.provider-list,
.bm-bg-gray {
    background: #edf0f4;
    position: relative;
}

.wa-for-busi {
    min-height: calc(100vh - 8.6vh);
}

/* // Business Messaging Page END // */

/* Print Template */
/* For PDF template */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/dejavu-sans');

.pdf-temp-design .l-tab-view .accordion-button::after {
    transform: rotate(-90deg);
}

.pdf-temp-design .l-tab-view .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
}

.pdf-temp-design,
.pdf-temp-design .form-control,
.pdf-temp-design .input-group-text {
    font-size: 13px !important;
}

.temp-view {
    width: calc(100vw - 480px);
}

.temp-view .temp-main {
    padding: 0 20px 0;
    flex-grow: 1;
    position: relative;
    left: 0;
    right: 0;
}

.temp-view .temp-details {
    margin: 35px auto;
    max-width: 820px;
}

.temp-view .temp-details.orientation-landscape {
    max-width: initial;
    zoom: 70%;
}

.temp-view .temp-section {
    position: relative;
    margin-bottom: 35px;
    box-shadow: 0 0 6px #cccccc;
}

.temp-view .temp-section::after {
    display: block;
    clear: both;
    content: "";
}

.pdf-temp-setting-tooltip .tooltip-inner {
    max-width: 250px;
    font-size: 13px;
    text-align: left;
}

#cusTaFieldInfo .tooltip-inner {
    padding-right: 1rem;
}

.l-tab-view .choose-color input[type="color"] {
    width: 38px;
}

.l-tab-view .choose-color input[type="color"]::-webkit-color-swatch {
    border-radius: 0;
}

.text-dashed-underline {
    padding-bottom: 2px;
    border-bottom: 1px dashed #969696;
}

.temp-view .temp-page {
    font-family: Roboto, 'WebFont-Roboto', sans-serif;
    font-size: 8pt;
    color: #000000;
    background: #ffffff;
}

.temp-header,
.temp-footer {
    color: #000000;
    background: #ffffff;
}

/* .temp-bodySection {
    border-width: 1px;
    border-style: solid;
} */

.temp-orgName {
    font-size: 12pt;
    color: #000000;
}

.temp-title {
    font-size: 22pt;
    color: #000000;
}

.temp-bodySection table {
    white-space: normal;
}

.temp-addresstable {
    width: 100%;
    table-layout: fixed;
}

.temp-addresstable>thead>tr>th {
    padding: 1px 5px;
    background-color: #f2f3f4;
    font-weight: normal;
    /* border-bottom: 1px solid #9e9e9e; */
}

.temp-addresstable>tbody>tr>td {
    line-height: 15px;
    padding: 5px 5px 0px 5px;
    vertical-align: top;
    word-wrap: break-word;
}

.temp-detailstable>tbody>tr>td {
    width: 50%;
    vertical-align: top;
    /* border-top: 1px solid #9e9e9e; */
}

.temp-detailstable>tbody>tr>td>.info>span {
    width: 45%;
    padding: 1px 5px;
    display: inline-block;
    vertical-align: top;
}

.subject-block {
    margin-top: 0px;
    padding: 10px;
    /* border-top: 1px solid #9e9e9e; */
}

.subject-block-value {
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 14pt;
    margin-top: 5px;
}

.annexure-plain::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 -38px 10px -53px;
    border-top: 2px dashed #000;
}

/* .temp-itemtable {
    border-top: 1px solid #9e9e9e;
} */

.temp-itemtable tr td:first-child,
.temp-itemtable tr th:first-child {
    border-left: 0px;
}

.temp-itemtable tr td:last-child,
.temp-itemtable tr th:last-child {
    border-right: 0px;
}

.temp-itemtable-header {
    font-size: 8pt;
    font-weight: normal;
    color: #000000;
    background-color: #f2f3f4;
    /* border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e; */
}

.temp-itemtable-breakword {
    word-wrap: break-word;
}

.breakrow-after {
    page-break-after: auto;
}

.breakrow-inside {
    page-break-inside: avoid;
}

.temp-itemtable tbody>tr>td {
    padding: 1px 5px;
    word-wrap: break-word;
}

.temp-item-row {
    font-size: 8pt;
    background-color: #FFFFFF;
    color: #000000;
    /* border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e; */
}

/* .temp-item-desc {
    color: #333333;
    font-size: 8pt;
} */

.temp-totaltable tbody>tr>td {
    padding: 4px 7px 0px;
    text-align: right;
}

.sign-img-prev {
    height: 31px;
    width: 232px;
    border: 1px solid #ddd;
}

/* // Print Template END // */

/* Responsive Design */
@media (min-width: 768px) {
    .sidebar.main-sidebar {
        top: 41px;
        bottom: 0;
    }

    .main-sidebar .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important;
        /* height: calc(100vh - 72px); */
        /* height: calc(100vh - 63px); */
        scrollbar-width: thin;
    }

    .with-sidebar .main-content {
        width: calc(100% - 72px);
        margin-left: 72px;
    }
}

@media (min-width:992px) {
    .top-header .module-links-list {
        max-width: calc(100vw - 36vw);
    }

    .top-header .module-links-list>li>.btn {
        margin-right: 4px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .top-header ul.dropdown-menu>.h-menu-link {
        display: list-item;
    }

    .sidebar.main-sidebar {
        top: 41px;
    }

    .custom-col-lg {
        flex: 0 0 20%;
        max-width: 20%;
    }

}

@media (min-width:1400px) {
    .top-header ul.navbar-nav>.h-menu-link {
        display: list-item;
    }
}

@media (max-width: 991.98px) {

    /* Header Mobile menu design */
    .top-header .module-links-list>li {
        align-self: stretch;
    }

    .top-header .navbar-nav.module-links-list>li .btn {
        width: 100%;
        justify-content: start;
        margin: 0 auto;
    }

    .top-header .navbar-nav.module-links-list>li .dropdown-menu {
        width: 100%;
        height: auto;
    }

    .top-header .offcanvas-backdrop {
        z-index: 1040;
    }

    /* // Header Mobile menu Design // */
}

/* Multi select box floating label design */
.form-normal .multi-select label {
    transform: scale(.85) translateY(-0.8rem) translateX(.15rem);
    z-index: 1;
    position: absolute;
    color: #212529a6;
}

.form-normal .multi-select label::after {
    position: absolute;
    inset: 0rem -0.32rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
}

.form-floating .multi-select input::placeholder {
    visibility: hidden;
    opacity: 0;
}

.form-floating .multi-select:focus input::placeholder {
    visibility: visible;
    opacity: 1;
}

.form-floating .multi-select:focus~label {
    height: fit-content;
}

.form-floating .multi-select:focus~label::after {
    position: absolute;
    inset: 0.5rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: #ffffff;
    border-radius: 0.375rem;
}

.tox .tox-statusbar {
    display: none !important;
}

.selected-template {
    background-color: rgb(240, 240, 240);
    padding: 5px 0 5px 15px;
    margin-top: 10px;
    border-left: 3px solid lightgrey;
}

/* email composer-offcanvas */
.email-composer.timeline-offcanvas.offcanvas.offcanvas-end.show {
    width: 65% !important;
}

/* workflow */
.wf-content {
    background: rgb(228, 238, 235) !important;
    min-height: 600px;
}

.card-border {
    border: 1px solid #338cf0 !important;
    box-shadow: 0 0 6px #338cf0 !important;
}

.ql-editor,
.ql-editor.ql-blank {
    height: 300px !important;
}

.ql-container {
    height: auto !important;
}