.pick-list-option {
    max-width: 600px;
    margin: 20px auto;
}
.drag-handle {
    cursor: move;
    margin-right: 10px;
    color: #6c757d;
}
.form-control.pl-40 {
    padding-left: 40px !important;
}
.form-group {
    position: relative;
}
.form-group .drag-handle {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.settings-icon {
    cursor: pointer;
    color: #6c757d;
    font-size: 1.2rem;
}

.pick-list-box {
    height: 150px;
    overflow-y: auto;
}

.custom-box {
    width: 400px;
    height: auto;
    padding: 10px;
}

.tooltip-container {
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 5px;
}
.tooltip-container textarea {
    background-color: #f1f4ff;
    border-color: #ced4da;
    color: #495057;
}
.info-icon {
    display: inline-block;
    vertical-align: middle;
}
.predefined-choices-list-box {
    height: 350px;
    overflow-y: auto;
}
.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }