/* Common Styles */
body,
/* div,
span,
li, */
.form-control, 
.dropdown-menu {
  font-size: 14px;
}

table {
  white-space: pre;
}

/* // Common Styles // */

/* Dashboard Cards Page */
.content-section {
  background-color: #FFFFFF !important;
}

/* // Dashboard Cards Page // */

.dropdown-toggle::after {
  display: none !important;
}

/* Model Css */
.container {
  margin-top: 20px;
}


.button-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.button-group button {
  width: 100%;
  height: 50px;
}


.fixed-size-button {
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.modal-dialog.custom-modal-size {
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
}


.modal-content {
  border-radius: 0.5rem;
}

.modal-header,
.modal-footer {
  padding: 1rem;
}

.modal-title {
  font-size: 1.25rem;
}

.modal-footer .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.modal-body .form-group {
  margin-bottom: 1rem;
}

.modal-body .form-control,
.modal-body .form-check {
  width: 100%;
}

.modal-body .input-group>.form-control,
.modal-body .input-group>.form-select {
  width: 1%;
}

.modal-body .form-check {
  margin-right: 1rem;
}

.modal-body .input-group {
  margin-top: 0.5rem;
}


.rollup-summary {
  padding: 1rem;
}

.rollup-summary .card-header {
  font-size: 1.25rem;
}

.rollup-summary .list-group-item.active {
  background-color: #007bff;
  border-color: #007bff;
}

.rollup-summary .list-group-item .badge {
  margin-left: 0.5rem;
}

.rollup-summary .form-group {
  margin-bottom: 1rem;
}

.rollup-summary .form-control {
  width: 100%;
}

.rollup-summary .btn {
  margin-right: 0.5rem;
}


.modal-footer .btn-danger,
.modal-footer .btn-primary {
  margin-right: 0.5rem;
}

.modal-body p {
  margin-bottom: 1rem;
}

.modal-body .btn-group {
  display: flex;
  justify-content: space-between;
}

.modal-body .btn-group .btn {
  flex: 1;
  margin: 0 0.5rem;
}

.create-box-dragover {
  height: 44px;
  background-color: #E6F4FF;
  border: 2px dashed #999;
  margin-bottom: 8px;
  margin-top: 8px;
  transition: all 0.3s ease;
}

.create-box-section-drag {
  height: 100px;
  background-color: #E6F4FF;
  border: 2px dashed #999;
  transition: all 0.3s ease;
}

.corner-setting-icon {
  position: absolute;
  right: 7px;
  top: 6px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu ul li.text-danger {
  color: red;
}

.module-name-popup {
  width: 340px;
  position: absolute;
  z-index: 1000;
}

.fade.modal.show {
  z-index: 1055;
}

/* workflow */

.digy-workflow-card {
  padding: 10px;
}

.card {
  padding: 10px;
}

.digy-workflow-table td,
.wf-span,
.wf-card p,
b {
  text-transform: capitalize;
}

.wf-card {
  margin: 10px 0px;
}

.w-22 {
  width: 22% !important;
}

.w-25 {
  width: 25% !important;
}

.w-10 {
  width: 10% !important;
}

.w-50 {
  width: 50% !important;
}

.modal-90w {
  min-width: 90% !important;
}

/* Fixed Table header */
/* Table Container for horizontal and vertical scroll */
.table-scroll-container {
  width: 100%;
  min-height: 200px;
  /* Adjust based on our preference */
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
}

/* Sticky Table Header */
.table-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Table or content styles */
.table-scroll-container table {
  border-collapse: collapse;
  width: 100%;
}

.table-scroll-container th,
.table-scroll-container td {
  /* Adjust for horizontal scrolling */
  /* min-width: 150px;  */
  min-width: 210px;
  /* max-width: 150px; */
  max-width: 210px;
  word-break: break-word;
  white-space: break-spaces;
}

.form-module .table-scroll-container {
  max-height: calc(100vh - 182px);
}

.sub-form-module .table-scroll-container {
  /* max-height: calc(100vh - 182px); */
  /* max-height: calc(100vh - 52.8vh); */
  max-height: calc(100vh - 44.8vh);

}

/* // Fixed Table Header // */

/* approval page */

.approval-card {
  background-color: #FFF;
}

.approval-card-selected {
  background-color: rgb(233, 242, 248);
}

.approval-card-selected,
.approval-card {
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.multiSelectContainer .optionListContainer {
  z-index: 3;
}

.multiSelectContainer .searchWrapper>.chip {
  font-size: 12px;
  line-height: 14px;
}

.multiSelectContainer li:hover {
  background: #e7f2ff !important;
  color: #000000 !important;
}

/* Integration Page */

.integration-header {
  background-color: #f8f9fa;
  padding: 2rem;
}

.integration-section {
  padding: 2rem 0;
}

.integration-flow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.integration-box {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
}

.integration-box h5 {
  margin-bottom: 1.5rem;
}

.arrow {
  font-size: 2rem;
  color: #6c757d;
}

/* End integration Page */

/* Sub Form Css */
.form-builder-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* max-width: 793px; */
  overflow-x: visible;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f8f9fa;
}

.add-fields-box {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-overflow-auto {
  overflow: auto;
}

.custom-overflow-auto::-webkit-scrollbar {
  height: 5px;
}

.custom-overflow-auto::-webkit-scrollbar-thumb {
  background: grey;
}

/* End Sub Form Css */

.sub_options {
  background-color: #f8faff;
  padding: 10px 5px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.article-donut-chart {
  height: 130px;
}

.ag-root-wrapper {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

/* Formula Property */
.scrollable-modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.formula-dropdown-options {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  padding: 5px 0;
  position: relative;
}


.return-type-dropdown {
  max-height: calc(4 * 40px);
  overflow-y: auto;
}

.decimal-places-dropdown {
  max-height: calc(3 * 40px);
  overflow-y: auto;
}

.function-dropdown {
  max-height: calc(3 * 40px);
  overflow-y: auto;
}

.field-dropdown {
  max-height: calc(4 * 40px);
  overflow-y: auto;
}

.operator-dropdown {
  max-height: calc(4 * 40px);
  overflow-y: auto;
}

.formula-dropdown-options div {
  padding: 5px;
  cursor: pointer;
}

.formula-dropdown-options div:hover {
  background-color: blue;
  color: white;
}

.insert-button {
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
}

.insert-button:hover {
  background-color: #0056b3;
}

.check-syntax-button {
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
}

.check-syntax-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  height: 40px;
  width: 100px;
  background-color: #dc3545;
  color: white;
  margin: 5px;
}

.cancel-button:hover {
  background-color: rgb(219, 33, 33);
}

.done-button {
  height: 40px;
  width: 100px;
  background-color: #007bff;
  color: white;
  margin: 5px;
}

.hovered {
  background-color: #007bff;
  color: white;
}


.formula-dropdown-options div:hover {
  cursor: pointer;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.ag-row-focus {
  z-index: 999;
}

.ag-list.ag-select-list{
  z-index: 1000;
}
/* End Formula Property */

.chart-loader .rounded-full svg {
  width: 50%;
  height: 100%;
}

.grid-main-container {
  height: calc(100vh - 18vh);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit,
      minmax(100px, 1fr));
  grid-template-rows: repeat(auto-fit,
      minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
}

.grid-item {
  background-color: #ffffff;
  /* White background for grid items */
  border: 1px solid #dcdcdc;
  /* Light grey border */
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.grid-item:hover {
  background-color: #f7f9fc;
}

.bg-image-box {
  background-color: #f5f5f5;
  background-image: linear-gradient(to right, rgb(248, 248, 248) 10px, transparent 0px), linear-gradient(rgb(248, 248, 248) 10px, transparent 0px);
  /* background-size: 89px 40px; */
  background-size: 8.28% 40px;
}

.react-grid-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  background-color: #f5f5f5;
}

.react-grid-placeholder {
  background: rgba(0, 0, 0, 0.1);
  border: 1px dashed #d0d0d0;
}

.canvas-drop {
  flex: 1;
  border: 1px solid #ced4da;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.ag-filter-menu {
  z-index: 999 !important;
}

.top-right-menu {
  z-index: 9999;
}

.react-grid-item>.content {
  height: 100%;
}

.react-grid-item>.card>.card-body {
  height: calc(100% - 26px);
}

.react-grid-item>.card>.card-body>.apexcharts-legend {
  margin-top: auto;
  margin-bottom: auto;
}

/* Filter Data */
.logic-symbol {
  text-align: center;
  font-weight: bold;
}

.criteria-number {
  font-weight: bold;
  text-align: center;
}

.nested-dropdown.dropdown > ul > li, 
.nested-dropdown.dropdown-submenu > ul > li {
  line-height: 22px;
}

.nested-dropdown .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.nested-dropdown .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0;
  margin-top: -0.1rem;
}

.nested-dropdown .dropdown-submenu {
  position: relative;
}

.end-unset {
  right: unset !important;
}

.vertical-timeline::before {
  background: black !important;
}

.timeline-offcanvas, .offcanvas-backdrop {
  z-index: 1056;
}

.modal-loader, .iti--inline-dropdown .iti__dropdown-content, .z-index-3 {
  z-index: 3 !important;
}

.text-new-line {
  white-space: pre-line;
}

.iti {
  width: 100%;
  display: block;
}

.db-table th {
  font-weight: 400;
}

.cursor-move {
  cursor: move;
}

/* Reminder Modal */

.reminder-modal-open {
  overflow: auto !important;
}

.reminder-modal {
  width: auto;
}

.reminder-modal .modal-dialog {
  position: fixed;
  top: 45px;
  right: 0;
  left: unset !important;
  width: 350px;
  height: fit-content;
  margin: 0;
}

.reminder-container {
  max-width: 400px;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.reminder-header {
  background-color: #d9534f; /* Red background */
  color: white;
  padding: 10px 15px;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-content {
  padding: 15px;
  background-color: #f7f8fa;
}

.now-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #6c757d;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.reminder-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.reminder-date {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.related-lead {
  font-size: 0.9rem;
  color: #555;
}

/* End reminder Modal */

/* Repeat Css */

.day-selector button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.day-selector button.active {
  background-color: #007bff;
  color: white;
}

/* Participants css */
.max-participants-warning {
  color: #ff9800;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.contact-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
}

.custom-scrollbar {
  scrollbar-width: bold;
  scrollbar-color: #888 #f1f1f1;
}
